body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;1,400;1,500;1,800&family=Poppins:wght@100;200;300;400;700&display=swap');
body, h1, h2, h3, h4, h5{
  font-family: 'Poppins', sans-serif;
}
